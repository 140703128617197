import axios from "axios";
import Swal from "sweetalert2";

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  withCredentials: true,
});

export const detectError = (error) => {
  let errors = {};
  const err = error.response.data;
  if (typeof err.data === "object" && err.data.length > 0) {
    err.data.forEach((e) => {
      errors[e.path] = e.message;
    });
  } else if (err && err.message) {
    errors = err.message;
  } else {
    errors = error.message;
  }

  return errors;
};

const adminClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL + "/admin",

  withCredentials: true,
});

adminClient.interceptors.response.use(undefined, (err) => {
  const error = err.response;
  const isAuth = error.config?.url?.startsWith("/auth");
  // if error is 401
  if (
    !isAuth &&
    error.status === 401 &&
    error.config &&
    !error.config.__isRetryRequest
  ) {
    Swal.fire({
      title: "שגיאת התחברות",
      text: "מיד תעברו לעמוד התחברות",
      timer: 2000,
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false,
    }).then(() => {
      location.reload();
    });
  }
});

export const adminApiClient = adminClient;

export default apiClient;
