<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.88 25.88">
    <g id="a" />
    <g id="b">
      <g id="c">
        <g>
          <line class="d" x1="1.5" y1="12.94" x2="24.38" y2="12.94" />
          <line class="d" x1="12.94" y1="1.5" x2="12.94" y2="24.38" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.d {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
}
</style>
